export default {
  LIGHT: {
    colors: {
      primary: '#fff',
      secondary: '#272822',
      grey: '#f1f1f1',
      shadow: 'rgba(0, 0, 0, 0.06) 0px 6px 20px',
      danger: '#96281B',
      warning: '#f15a22',
      info: '#1f3a93',
      success: '#1E824C',
      light_success: '#AFD2A9',
      success_gradient: 'linear-gradient(90deg, #1E824C 0%,#AFD2A9 100%);',
    },
  },
  DARK: {
    colors: {
      primary: '#272822',
      secondary: '#f1f1f1',
      grey: '#404040',
      shadow: 'rgba(255, 255, 255, 0.06) 0px 6px 20px',
      danger: '#ec644b',
      warning: '#f39c12',
      info: '#19b5fe',
      success: '#7befb2',
      light_success: '#AFD2A9',
      success_gradient: 'linear-gradient(90deg, #7befb2 0%,#AFD2A9 100%);',
    },
  },
}
