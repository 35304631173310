import { createGlobalStyle } from 'styled-components'
import remcalc from 'remcalc'

export default createGlobalStyle`
  html {
    font-size: 100%; /* set base font-size for remcalc */
    font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  body {
    background: ${props => props.theme.primary};
    color: ${props => props.theme.secondary};
    padding: 0;
    margin: 0;
    input {
      height: 35px;
      margin: 5px 0;
      font-size: 15px;
      border: 1px solid ${props => props.theme.grey};
      border-radius: 5px;
      font-size: 1.2rem;
      padding: 10px;
      width: 100%;
      margin-bottom: 20px;
    }
    textarea {
      border: 1px solid ${props => props.theme.grey};
      border-radius: 5px;
      font-size: 1.2rem;
      padding: 10px;
      width: 100%;
      height: 200px;
    }
    select {
      border: 1px solid ${props => props.theme.grey};
      border-radius: 5px;
      font-size: 1.2rem;
      padding: 10px;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    border: none;
    background-color: ${props => props.theme.colors.success};
    color: ${props => props.theme.colors.primary};
  }

  html, body, #app {
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colors.info};
    overflow-x: hidden;
  }

  body {
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    min-width: 320px;
    background-color: ${props => props.theme.colors.info};
    font-size: 14px;
    line-height: 1.4285em;
    color: ${props => props.theme.secondary};
  }

  input, textarea {
    color: ${props => props.theme.secondary} !important;
    border: none !important;
    border-radius: 0 !important;
  }

  div {
    box-sizing: border-box;
  }

  h1, h2, h3, h4 {
    letter-spacing: 0.4px;
    svg {
      margin-right: 20px;
    }
  }

  h1, h2, h3 {
    font-size: ${remcalc(36)};
  }

  h2 {
    font-weight: 700;
    color: ${props => props.theme.secondary};
  }

  h3 {
    font-weight: 600;
    color: ${props => props.theme.secondary};
  }

  h4, h5, h6 {
    font-size: ${remcalc(16)};
  }

  h4 {
    font-weight: 400;
    color: ${props => props.theme.secondary};
  }

  h5, h6 {
    font-weight: 300;
    color: ${props => props.theme.secondary};
  }

  p {
    font-size: ${remcalc(24)};
    line-height: 1.5;
  }

  small {
    font-size: ${remcalc(18)};
    line-height: 1.5;
    display: block;
  }

  button {
    text-transform: uppercase;
    padding: 15px 25px !important;
    font-weight: 800;
    font-size: ${remcalc(20)};
    border-radius: ${remcalc(30)} !important;
    &:disabled {
      color: ${props => props.theme.colors.secondary} !important;
      background-color: ${props => props.theme.colors.primary} !important;
      cursor: not-allowed;
    }
    & + button {
      margin-left: 20px;
    }
  }

`
