import axios from 'axios'
import cookies from 'js-cookie'

export const userLogin = async ({ email, password }) => {
  try {
    const postTokensResult = await axios.post(`/auth/tokens`, {
      user: {
        email,
        password,
      },
    })

    return postTokensResult.data.token
  } catch (e) {
    const { response = {} } = e
    const { status = 500, data = { message: "internal server error" } } = response

    if (status === 401) { 
      throw new Error("invalid email and password combination")
    }

    throw new Error(`sorry, something went wrong: ${data.message}`)
  }
}

export const verifyToken = async () => {
  try {
    const res = await axios.get(`/auth/tokens/verify`, { 
      headers: {
        Authorization: `Bearer ${cookies.get('token')}`,
      }
    })

    return res.data
  } catch (e) {
    throw new Error(e)
  }
}