import axios from 'axios'
import cookies from 'js-cookie'

const axiosInstance = axios.create({
  withCredentials: true,
})

axiosInstance.interceptors.request.use(function(config) {
  if (config.url.includes('/api/v2')) {
    config.headers = {
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  }

  return config
})

axiosInstance.interceptors.response.use(function(response) {
  return response
})

export default axiosInstance
