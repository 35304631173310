import React, { useContext } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import GlobalContext from '../context/globalContext'

const LogoutButton = styled.div`
  position: fixed;
  font-size: 20px;
  top: 20px;
  right: 20px;
  cursor: pointer;
  svg {
    margin-left: 10px;
  }
`

function Logout() {
  const [{ isAuthenticated }, toggles] = useContext(GlobalContext)
  return (
    isAuthenticated && (
      <LogoutButton onClick={() => toggles.toggleAuthenticated()}>
        Logout
        <FontAwesomeIcon icon={faUser} />
      </LogoutButton>
    )
  )
}

export default Logout
