import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToolbox } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom'

import GlobalContext from '../context/globalContext'
import { OuterContainer, InnerContainer } from '../components/StyledComponents'

import { userLogin } from '../api/auth'

function Login({ history }) {
  const [values, setValues] = useState({})
  const [context, toggles] = useContext(GlobalContext)
  const [error, setError] = useState(null)

  async function onSubmit(e) {
    e.preventDefault()
    try {
      setError(null)
      const token = await userLogin({
        ...values,
        clientToken: context.clientToken,
      })
      toggles.toggleAuthenticated(token)
      if (document.referrer.includes('.tools')) {
        return (window.location = document.referrer)
      }
      return history.push(`/`)
    } catch (err) {
      setError(err)
    }
  }

  return (
    <OuterContainer>
      <InnerContainer>
        <h1>
          <FontAwesomeIcon icon={faToolbox} />
          Login
        </h1>
        <form onSubmit={onSubmit}>
          <input
            name="email"
            type="email"
            placeholder="Email Address"
            autoComplete="new-email"
            onChange={e =>
              setValues({ ...values, [e.target.name]: e.target.value })
            }
            value={values.email}
            required
          />
          <input
            name="password"
            placeholder="Password"
            autoComplete="new-password"
            type="password"
            onChange={e =>
              setValues({ ...values, [e.target.name]: e.target.value })
            }
            value={values.password}
            required
          />
          <button type="submit" color="success">
            Login
          </button>
          {error && <small>{error.message}</small>}
        </form>
      </InnerContainer>
    </OuterContainer>
  )
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(Login)
