import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Loader } from '@cloud/mcloud-ui'

import GlobalContext from '../../context/globalContext'
import { verifyToken } from '../../api/auth'

const PrivateRoute = ({ component: Component, render, ...rest }) => {
  const [{ isAuthenticated }, toggles] = useContext(GlobalContext)

  const checkAuth = async () => {
    try {
      await verifyToken()
    } catch (e) {
      if (isAuthenticated) {
        toggles.toggleAuthenticated()
      }
    }
  }
  
  useEffect(() => {
    checkAuth()
  }, [checkAuth])

  return (
    <Route
      {...rest}
      render={props =>
        // eslint-disable-next-line no-nested-ternary
        isAuthenticated && render ? (
          render()
        ) : isAuthenticated ? (
          <React.Suspense fallback={<Loader loading />}>
            <Component {...rest} />
          </React.Suspense>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

PrivateRoute.defaultProps = {
  location: {},
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object,
  path: PropTypes.string.isRequired,
  render: PropTypes.func,
}

export default PrivateRoute
