import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import theme from './theme'
import GlobalContext from './context/globalContext'
import GlobalStyle from './globalStyle'
import { PrivateRoute, NonPrivateRoute } from './components'
import { useThemeMode, useAuthentication } from './hooks'

import { LoginPage, ToolPage, ManifestPage } from './pages'
import PermissionProvider from './context/permissionContext'

function App() {
  const [themeMode, toggleTheme] = useThemeMode()
  const [isAuthenticated, toggleAuthenticated] = useAuthentication()

  return (
    <div className="App">
      <ThemeProvider theme={theme[themeMode]}>
        <GlobalContext.Provider
          value={[
            { themeMode, isAuthenticated },
            { toggleTheme, toggleAuthenticated },
          ]}
        >
          <PermissionProvider>
            <Router>
              <Switch>
                <PrivateRoute exact path="/" component={ToolPage} />
                <NonPrivateRoute path="/login" component={LoginPage} />
                <PrivateRoute exact path="/manifest" component={ManifestPage} />
              </Switch>
            </Router>
            <GlobalStyle />
          </PermissionProvider>
        </GlobalContext.Provider>
      </ThemeProvider>
    </div>
  )
}

export default App
