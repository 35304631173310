import { useState } from 'react'
import cookies from 'js-cookie'

export default () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!cookies.get('token'))

  const toggleAuthenticated = (token = null) => {
    const location = window.location.hostname

    if (token) {
      cookies.set('token', token, { domain: location })
    } else {
      cookies.remove('token', { domain: location })
      cookies.remove('token', { domain: location.replace('tools.', '') })
    }
    setIsAuthenticated(!!token)
  }

  return [isAuthenticated, toggleAuthenticated]
}
