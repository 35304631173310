import styled from 'styled-components'

export const OuterContainer = styled.div`
  height: 100%;
  width: 100vw;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const InnerContainer = styled.section`
  max-width: 800px;
  text-align: justify;
  > p > a {
    text-decoration: underline;
  }
`
