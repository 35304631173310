import React from 'react'
import PropTypes from 'prop-types'

function Trim({ children, length }) {
  const truncate = text =>
    text.length > length ? `${text.substring(0, length + 1)}...` : text

  return <>{truncate(children)}</>
}

Trim.propTypes = {
  length: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Trim
