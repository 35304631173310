import React, { useContext } from 'react'
import ToolCard from './components/ToolCard'
import { PermissionContext } from '../../context/permissionContext'

function ToolList() {
  const { isLoading, isError, permittedTools } = useContext(PermissionContext)

  const renderGrid = () => {
    if (isError) {
      return <p>Oops, {isError}.</p>
    }
    return (
      <>
        {permittedTools.length !== 0 &&
          permittedTools.map((tool, index) => (
            <ToolCard id={index} key={index} data={tool} />
          ))}
      </>
    )
  }

  return isLoading ? <div>Loading...</div> : renderGrid()
}

export default ToolList
