import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice, faToolbox } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ManifestList, Logout, Environment } from '../components'
import { OuterContainer, InnerContainer } from '../components/StyledComponents'

const ToolsButton = styled(Link)`
  position: fixed;
  font-size: 20px;
  top: 20px;
  right: 140px;
  cursor: pointer;
  svg {
    margin-left: 10px;
  }
`

function ManifestPage() {
  return (
    <OuterContainer>
      <Environment />
      <ToolsButton to="/">Tools <FontAwesomeIcon icon={faToolbox} /></ToolsButton>
      <Logout />
      <InnerContainer>
        <h1>
          <FontAwesomeIcon icon={faFileInvoice} />
          Midas Cloud Production Manifest
        </h1>
        <ManifestList/>
      </InnerContainer>
    </OuterContainer>
  )
}

export default ManifestPage