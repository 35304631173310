import React from 'react'
import PropTypes from 'prop-types'

import LogoGenerator from '../../LogoGenerator'
import {
  Container,
  IconSection,
  Content,
  Title,
  Version,
} from './styles'

function ServiceCard({ data = {} }) {
  const { name = '', version = '' } = data

  return (
    <Container>
      <Content>
        <Title>
          <h3>{name}</h3>
          {version && <Version>{version}</Version>}
        </Title>
      </Content>
    </Container>
  )
}

ServiceCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    client: PropTypes.string,
    homepage: PropTypes.string.isRequired,
  }),
}

export default ServiceCard