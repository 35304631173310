import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faServer } from '@fortawesome/free-solid-svg-icons'

import { checkToolEnvironment } from '../api/tools'

const EnvironmentButton = styled.div`
  position: fixed;
  font-size: 14px;
  font-weight: bold;
  bottom: 20px;
  right: 20px;
  span {
    text-align: center;
  }
  svg {
    margin-right: 10px;
  }
`

function Environment() {
  const [environment, setEnvironment] = useState('')

  useEffect(() => {
    async function getEnvironment() {
      const data = await checkToolEnvironment()
      setEnvironment(data)
    }
    getEnvironment()
  }, [])
  return (
    <EnvironmentButton>
      <FontAwesomeIcon icon={faServer} />
      ENV:
      {environment.toUpperCase()}
    </EnvironmentButton>
  )
}

export default Environment
