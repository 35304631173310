import axiosInstance from '../helpers/axiosInstance'

export const getTools = async () => {
  try {
    const res = await axiosInstance.get(`/tools`)
    return res.data
  } catch (e) {
    throw new Error(e)
  }
}

export const checkToolStatus = async url => {
  try {
    const res = await axiosInstance.get(`${url}/_status`)
    return res.data
  } catch (e) {
    const { response = {} } = e
    throw new Error(response.status)
  }
}

export const checkToolPermission = async url => {
  try {
    const res = await axiosInstance.get(`${url}/_permission`)

    return res
  } catch (e) {
    const { response = {} } = e
    throw new Error(response.status)
  }
}

export const checkToolEnvironment = async () => {
  try {
    const response = await axiosInstance.get(`/_environment`)

    return response.data
  } catch (e) {
    throw new Error(e)
  }
}
