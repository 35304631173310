import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import { Loader } from '@cloud/mcloud-ui'
import GlobalContext from '../../context/globalContext'

const NonPrivateRoute = ({ component: Component, ...rest }) => {
  const [{ isAuthenticated }] = useContext(GlobalContext)

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        ) : (
          <React.Suspense fallback={<Loader loading />}>
            <Component {...rest} />
          </React.Suspense>
        )
      }
    />
  )
}

NonPrivateRoute.defaultProps = {
  location: {},
}

NonPrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object,
  path: PropTypes.string.isRequired,
}

export default NonPrivateRoute
