import React, { useEffect, useCallback, useState } from 'react'
import ServiceCard from './components/ServiceCard'
import { getManifest } from '../../api/manifest'

function ManifestList() {

  const [versions, setVersions] = useState([])

  const fetchStatus = useCallback(async () => {
    try {
      const v = await getManifest()
      setVersions(v)
  
    } catch (e) {
      const { message = '' } = e
      console.log(message)
    }
  }, [setVersions])

  useEffect(() => {
    fetchStatus()
  }, [fetchStatus])

  return (
    <>
      {versions.length !== 0 &&
        versions.map((service, index) => (
          <ServiceCard id={index} key={index} data={service} />
        ))}
    </>
  )
}

export default ManifestList