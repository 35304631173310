import styled from 'styled-components'

export const Container = styled.a`
  height: 100px;
  display: flex;
  position: relative;
  flex: 0 0 calc(calc(100% / 2) - 15px);
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex: 100% 0 1;
    margin: 10px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  width: 80%;
  h3,
  p {
    margin: 0 !important;
  }
  h3 {
    font-size: 26px;
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    padding-bottom: 10px;
  }
  ~ div {
    display: flex;
    width: 50%;
  }
`

export const Title = styled.div`
  flex-grow: 2;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding-right: 20px;
`

export const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Version = styled.span`
  font-size: 16px;
  padding: 3px 12px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  display: inline-block;
  margin-top: 10px;
  color: ${props => props.theme.colors.secondary};
  background: ${props => props.theme.colors.primary};
`

export const IconSection = styled.div`
  height: 100%;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
  svg {
    height: 100%;
    width: 150px;
    font-size: 50px;
    width: auto;
  }
`
