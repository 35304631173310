import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGraduationCap,
  faFileCode,
  faClipboardList,
  faBarcode,
  faToolbox,
  faTools,
  faBook,
  faUsers,
  faKey,
  faShieldAlt,
  faUpload,
} from '@fortawesome/free-solid-svg-icons'

export default function ToolLogo({ tool }) {
  const findLogo = () => {
    switch (tool) {
      case 'academy-builder':
        return faGraduationCap
      case 'log-viewer':
        return faFileCode
      case 'device-register':
        return faClipboardList
      case 'serial-checker':
        return faBarcode
      case 'tool-hub':
        return faToolbox
      case 'service-note':
        return faTools
      case 'composer':
        return faBook
      case 'user-register':
        return faUsers
      case 'seraph':
        return faKey
      case 'lando':
        return faShieldAlt
      case 'firmware-uploader':
        return faUpload
      default:
        return ''
    }
  }
  return <FontAwesomeIcon icon={findLogo()} />
}

ToolLogo.propTypes = {
  tool: PropTypes.string.isRequired,
}
