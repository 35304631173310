import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToolbox, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

import { ToolList, Logout, Environment } from '../components'
import { OuterContainer, InnerContainer } from '../components/StyledComponents'

const ManifestButton = styled(Link)`
  position: fixed;
  font-size: 20px;
  top: 20px;
  right: 140px;
  cursor: pointer;
  svg {
    margin-left: 10px;
  }
`

function ToolPage() {
  return (
    <OuterContainer>
      <Environment />
      <ManifestButton to="/manifest">Manifest <FontAwesomeIcon icon={faFileInvoice} /></ManifestButton>
      <Logout />
      <InnerContainer>
        <h1>
          <FontAwesomeIcon icon={faToolbox} />
          Midas Cloud Support Tools
        </h1>
        <p>
          Click a tool from the list below to access it. If you encounter unauthorized tools, 
          you may request access by contacting the cloud team. You can reach them via{' '}
          <a href="mailto:cloud@musictribe.com">email</a> or by connecting with any member of 
          the cloud team on Teams.
        </p>
        <ToolList />
      </InnerContainer>
    </OuterContainer>
  )
}

export default ToolPage
